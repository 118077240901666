
import { Component, Vue } from 'vue-property-decorator';
import { dispatchSubmitPasswordRecovery } from '@/store/main/actions';

@Component({})
export default class PasswordRecovery extends Vue {
  public username = '';
  public resetStep = 1;

  public async submitReset() {
    const response = await dispatchSubmitPasswordRecovery(this.$store, { email: this.username });
    if (response) {
      this.resetStep = 2;
    } else {
      this.$toast.error(this.$t('Что-то пошло не так').toString());
    }
  }
}

